<template>
  
  <div class="addequity">
    <meta http-equiv="Access-Control-Allow-Origin" content="*" />
    <frame :top1="top1" :top2="top2">
      <div>添加商品</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="选择商户" v-show="selectShow">
          <el-select
            v-show="merchantShow"
            style="width: 45%"
            v-model="business_id"
            placeholder="请选择商户"
            @change="changeMerchant(business_id)"
          >
            <el-option
              v-for="item in businessList"
              :key="item.business_id"
              :label="item.business_name"
              :value="item.business_id"
            >
            </el-option>
          </el-select>
          <el-select
            v-show="storeShow"
            style="width: 45%; margin-left: 10%"
            v-model="storeid"
            placeholder="请选择门店"
          >
            <el-option
              v-for="item in storeList"
              :key="item.storeid"
              :label="item.s_name"
              :value="item.storeid"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品分类" prop="oneSort">
          <el-select
            style="width: 45%"
            v-model="ruleForm.onesort"
            placeholder="一级分类"
          >
            <el-option
              v-for="item in equityList"
              :key="item.gtid"
              :label="item.t_name"
              :value="item.gtid"
              @click.native="labelClickType(item.t_name)"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 45%; margin-left: 10%"
            v-model="ruleForm.twosort"
            placeholder="二级分类"
          >
            <el-option
              v-for="item in equityListTwo"
              :key="item.ncid"
              :label="item.nclass"
              :value="item.ncid"
              @click.native="labelClickClass_name(item.nclass)"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="数字权益" prop="n_status">
          <el-select
            style="width: 100%"
            v-model="ruleForm.n_status"
            placeholder="请选择数字权益分类"
          >
            <el-option
              v-for="item in bigEquityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- 话费直冲  加油卡直冲 会员直充 短链接 卡密 -->
        </el-form-item>

        <el-form-item label="商品名称" prop="gname">
          <el-input v-model="ruleForm.gname"></el-input>
        </el-form-item>
        <el-form-item label="商品编码" prop="ng_code">
          <el-input v-model="ruleForm.ng_code"></el-input>
        </el-form-item>
        <el-form-item label="副标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="商品单价" prop="unitprice">
          <el-input v-model="ruleForm.unitprice"></el-input>
        </el-form-item>
        <el-form-item label="市场价" prop="marketprice">
          <el-input v-model="ruleForm.marketprice"></el-input>
        </el-form-item>
        <el-form-item label="库存" prop="stock">
          <el-input v-model="ruleForm.stock"></el-input>
        </el-form-item>
        <el-form-item label="是否上架">
          <el-switch v-model="ruleForm.shelves"></el-switch>
        </el-form-item>
        <el-form-item label="服务保障" prop="service">
          <el-checkbox-group v-model="ruleForm.service">
            <!-- <el-checkbox label="1" name="1">快速到账</el-checkbox>
            <el-checkbox label="2" name="2">精选权益</el-checkbox>
            <el-checkbox label="3" name="3">服务保障</el-checkbox> -->
            <el-checkbox
              v-for="(item, index) in textlist"
              :label="item.value"
              :key="index"
              name="type"
              class="favour_checkbox"
              >{{ item.lable }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="商品图片" prop="name">
          <el-upload
            class="avatar-uploader"
            :action="uploadingUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb，建议图片大小350*200px
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";
export default {
  name: "addequity",

  data() {
    return {
      top1: "13-3",
      top2: ["13"],
      imageUrl: "",
      uploadingUrl: "",
      businessList: [],
      storeList: [],
      storeShow: false,
      merchantShow: false,
      selectShow: true,
      business_id: "",
      storeid: "",
      type: "",
      className: "",
      equityList: [], //分类列表
      equityListTwo: [], //二级分类列表
      // editList: {}, //编辑的商品信息
      editId: "",
      // <!-- 话费直冲  加油卡直冲 会员直充 短链接 卡密 -->
      bigEquityList: [
        {
          id: 1,
          name: "话费直冲",
        },
        {
          id: 2,
          name: "加油卡直冲",
        },
        {
          id: 3,
          name: "会员直充",
        },
        {
          id: 4,
          name: "短链接",
        },
        {
          id: 5,
          name: "卡密",
        },
      ],
      textlist: [
        {
          value: "1",
          lable: "快速到账",
        },
        {
          value: "2",
          lable: "精选权益",
        },
        {
          value: "3",
          lable: "服务保障",
        },
      ],
      ruleForm: {
        onesort: "",
        twosort: "",
        gname: "",
        title: "",
        unitprice: "",
        marketprice: "",
        stock: "",
        shelves: false,
        service: [],
        gimg: "",
        n_status: "", //数字权益分类ID
        ng_code: "", //数字编码
      },
      rules: {
        n_status: [
          { required: true, message: "请选择数字权益分类", trigger: "change" },
        ],
        onesort: [
          { required: true, message: "请输入活动名称", trigger: "change" },
        ],
        twosort: [
          { required: true, message: "请输入商品名称", trigger: "change" },
        ],
        gname: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        ng_code: [
          { required: true, message: "请输入商品编码", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入商品标题", trigger: "blur" }],
        unitprice: [
          { required: true, message: "请输入商品单价", trigger: "blur" },
        ],
        marketprice: [
          { required: true, message: "请输入市场价", trigger: "blur" },
        ],
        stock: [{ required: true, message: "请输入库存", trigger: "blur" }],
        service: [
          {
            type: "array",
            required: true,
            message: "请选择服务",
            trigger: "blur",
          },
        ],
        gimg: [{ required: true, message: "请选择图片", trigger: "blur" }],
      },
    };
  },
  components: {
    frame,
  },

  mounted() {},

  methods: {
    //获取一级分类名称
    labelClickType(data) {
      console.log(data);
      this.type = data;
    },
    //获取二级分类名称
    labelClickClass_name(data) {
      this.className = data;
      console.log(this.className);
    },
    //请求分类列表
    getNumGoodsClassData(storeid) {
      this.$request
        .getNumGoodsClassData({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          login_type: this.$storage.getLocal("type"), //身份
          storeid: storeid,
        })
        .then((res) => {
          this.equityList = res.data.typeData;
          this.equityListTwo = res.data.classDatas;
        });
    },
    //选中商户查询门店
    changeMerchant(business_id) {
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          this.storeList = res.data;
          this.getNumGoodsClassData(business_id);
        });
    },
    //图片上传操作
    handleReset(name) {
      this.$refs[name].resetFields();
      this.imageUrl = "";
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.ruleForm.gimg = res.data.src;
    },
    beforeAvatarUpload(file) {
      console.log("上传");
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    submitForm(formName) {
      // return;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.className);
          // return;
          //编辑商品
          if (this.editId) {
            this.$request
              .upNumGoodsData({
                token: this.$storage.getLocal("token"), //登陆进来的tooken
                login_type: this.$storage.getLocal("type"), //身份
                storeid: this.storeid,
                typeid: this.ruleForm.onesort,
                type: this.type,
                ncid: this.ruleForm.twosort,
                class_name: this.className,
                ng_name: this.ruleForm.gname,
                title: this.ruleForm.title,
                original_price: this.ruleForm.unitprice,
                selling_price: this.ruleForm.marketprice,
                stock: this.ruleForm.stock,
                is_show: this.ruleForm.shelves == "false" ? 2 : 1,
                services: this.ruleForm.service,
                img: this.ruleForm.gimg,
                n_status: this.ruleForm.n_status,
                ng_code: this.ruleForm.ng_code,
                nid: this.editId,
              })
              .then((res) => {
                if (res.code == 0) {
                  this.$notify({
                    title: "修改成功",
                    type: "success",
                  });
                  this.$router.push({ path: "equitylist" });
                } else {
                  alert("请求失败");
                }
              });
          } else {
            this.$request
              .numGoodsAddData({
                token: this.$storage.getLocal("token"), //登陆进来的tooken
                login_type: this.$storage.getLocal("type"), //身份
                storeid: this.storeid,
                typeid: this.ruleForm.onesort,
                type: this.type,
                ncid: this.ruleForm.twosort,
                class_name: this.className,
                ng_name: this.ruleForm.gname,
                title: this.ruleForm.title,
                original_price: this.ruleForm.unitprice,
                selling_price: this.ruleForm.marketprice,
                stock: this.ruleForm.stock,
                is_show: this.ruleForm.shelves == "false" ? 2 : 1,
                services: this.ruleForm.service,
                img: this.ruleForm.gimg,
                n_status: this.ruleForm.n_status,
                ng_code: this.ruleForm.ng_code,
              })
              .then((res) => {
                if (res.code == 0) {
                  this.$notify({
                    title: "添加成功",
                    type: "success",
                  });
                  this.$router.push({ path: "equitylist" });
                } else {
                  alert("请求失败");
                }
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.uploadingUrl = this.$request.imgUrl() + "PHPExcel/fileUpload";
    console.log(this.uploadingUrl);
    //请求商户列表接口
    this.$request.businessList({}).then((res) => {
      this.businessList = res.data;
    });
    this.admin_type = this.$storage.getLocal("type");
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (this.admin_type == 2) {
      this.storeShow = true;
      this.changeMerchant("");
    }
    if (this.admin_type == 3) {
      this.selectShow = false;
      this.getNumGoodsClassData("");
    }
    let editId = this.$route.query.id;
    if (editId) {
      this.editId = editId;
      this.$request
        .selNumGoodsInfo({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          nid: editId,
        })
        .then((res) => {
          this.business_id = res.data.pstoreid;
          this.changeMerchant(res.data.pstoreid);
          this.storeid = res.data.storeid;
          this.ruleForm.onesort = res.data.typeid;
          this.ruleForm.twosort = res.data.ncid;
          this.ruleForm.gname = res.data.ng_name;
          this.ruleForm.title = res.data.title;
          this.ruleForm.unitprice = res.data.original_price;
          this.ruleForm.marketprice = res.data.selling_price;
          this.ruleForm.stock = res.data.stock;
          this.ruleForm.shelves = res.data.is_show == 1 ? true : false;
          this.ruleForm.service = res.data.services.match(/\d+(\.\d+)?/g);
          this.ruleForm.gimg = res.data.img;
          this.ruleForm.n_status = res.data.n_status;
          this.ruleForm.ng_code = res.data.ng_code;
          this.imageUrl = this.$request.imgUrl2() + "uploads/" + res.data.img;
          this.type = res.data.type;
          if (res.data.ncid == 1) {
            this.className = "充值";
          } else if (res.data.ncid == 2) {
            this.className = "卡密";
          } else if (res.data.ncid == 3) {
            this.className = "短链接";
          }
        });
    }
  },
};
</script>

<style scoped>
.demo-ruleForm {
  width: 30%;
  margin-top: 20px;
  margin-left: 500px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed black;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>